import setLanguage from 'next-translate/setLanguage';

import { USEFUL_LINKS } from './UsefulLinks';

export const PRODUCTS_MENU = [
  {
    text: 'inkan-platform',
    link: () => USEFUL_LINKS.INKAN_PLATFORM(),
  },
  {
    text: 'inkan-integration',
    link: () => USEFUL_LINKS.INKAN_INTEGRATION(),
  },
];

export const SOLUTIONS_MENU = [
  {
    text: 'inspections-and-certifications',
    link: () => USEFUL_LINKS.INSPECTIONS(),
  },
  {
    text: 'insurance-warranties',
    link: () => USEFUL_LINKS.INSURANCE(),
  },
];

export const LANGUAGE_ITEMS = [
  {
    text: 'English',
    onClick: async () => await setLanguage('en'),
  },
  {
    text: 'Español',
    onClick: async () => await setLanguage('es'),
  },
];
