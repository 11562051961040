import { useQuery } from 'react-query';

import api from '@/lib/api';

import { API_ENDPOINTS } from '@/constants/ApiConfig';
import { QUERIES } from '@/queries';

import { UserProfile } from '@/types';

const fetchUserProfile = async () => {
  const { data } = await api.get(API_ENDPOINTS.USER_PROFILE());

  return data.data;
};

export default function useUser() {
  const { data, error, refetch } = useQuery<UserProfile>(
    QUERIES.user,
    fetchUserProfile,
    {
      refetchOnWindowFocus: false,
    }
  );

  return {
    user: data,
    isAdminUser: data?.range === 'RANGE_ADMIN',
    isLoading: !error && !data,
    isError: error,
    refetchUser: refetch,
  };
}
