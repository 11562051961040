/* eslint-disable @typescript-eslint/no-unused-vars */
export const USEFUL_LINKS = {
  IOS_STORE: process.env.NEXT_PUBLIC_IOS_STORE as string,
  ANDROID_STORE: process.env.NEXT_PUBLIC_ANDROID_STORE as string,
  TERMS_AND_CONDITIONS: (lang: string) => '/terms-conditions',
  PRIVACY_POLICY: (lang: string) => '/privacy-policy',
  APP: (lang: string) => '/',
  FAQ: (lang: string) => 'https://inkan.media/#faq',
  CLIENTS: (lang: string) => 'https://inkan.media/#clients',
  HOW_IT_WORKS: (lang: string) => 'https://www.youtube.com/watch?v=I_YwCLPgXH4',
  LEGAL_NOTICE: '/',
  DASHBOARD: () => '/dashboard',
  VERIFIER: () => '/verify',
  SEARCH_ENGINE: () => '/search',
  CONTACT_US: '/contact',
  EC_EUROPE: () =>
    'https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage',
  INKAN_PLATFORM: () => 'https://inkan.media/platform',
  INKAN_INTEGRATION: () => 'https://inkan.media/integration',
  INSPECTIONS: () => 'https://inkan.media/inspections-and-certifications',
  INSURANCE: () => 'https://inkan.media/insurance-warranties',
};
