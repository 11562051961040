import useTranslation from 'next-translate/useTranslation';

import { LANGUAGE_ITEMS } from '@/constants/HeaderItems';

import HeaderPopoverItem from '../layouts/LandingPageNavbar/HeaderPopover';
const LanguageSelector = () => {
  const { lang } = useTranslation('common');
  return (
    <HeaderPopoverItem
      itemText={lang === 'en' ? 'English' : 'Español'}
      items={LANGUAGE_ITEMS}
      buttons
      className='mr-6'
      buttonLink=''
    />
  );
};

export default LanguageSelector;
