import api from '@/lib/api';

import { FiltersProps } from '@/stores/filters';

import { CertificationDashboard } from '@/types';

export default async function fetchCertifications(filters: FiltersProps) {
  const res = await api.get<{ data: CertificationDashboard[] }>(
    '/certifications/list',
    {
      params: filters,
    }
  );
  const { data } = res.data;

  return data.filter((certification) => certification.status === 'DONE');
}
