import { signOut } from 'next-auth/react';
import useTranslation from 'next-translate/useTranslation';
import { useCallback } from 'react';

export default function useLogout() {
  const { lang } = useTranslation();
  const logout = useCallback(
    (callbackUrl = `/${lang}`) => {
      signOut({ callbackUrl });
    },
    [lang]
  );

  return logout;
}
